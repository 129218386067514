import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { BasePage, Maybe } from "../types/graphql";
import { DynamicComponent } from "@components/DynamicComponent";
import { SidebarProps as SanitySidebar } from "../types/sanity";
import { getHRef, getLinkLabel, isLive } from "../utils";
import type { SidebarProps } from "@components/Main/type";
import { UnpublishedProvider, useBadUrls } from "../context/Unpublished";
import { useSmoothScroll } from "@/hooks/useSmoothScroll";

import { heroAtom, seoAtom, invertedHeroAtom } from "@/store";

import { Layout } from "@components/Layout";
import { Container } from "@components/Layout/Container";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { Main } from "@components/Main";
import { Helmet } from "react-helmet";
import { titleCase } from "title-case";
import { useHydrateAtoms } from "jotai/utils";
import { EmailListForm } from "../components/EmailListForm";

export type PageTemplateProps = {
  data: {
    hero: any;
    allNonVersionedDocuments?: any;
    sanityPage: BasePage & {
      hero: any;
      includeEmailForm: boolean;
      hero2: any;
      publicationStatus: Maybe<string>;
      sidebar: Maybe<SanitySidebar>;
      heading: Maybe<string>;
      invertHeroTheme: Maybe<boolean>;
      stretch: Maybe<boolean>;
    };
  };
} & PageProps;

const getHero = (...heros: any[]) => heros.filter(Boolean)[0];

const PageTemplate = ({ data }: PageTemplateProps) => {
  useSmoothScroll();
  const [badUrls] = useBadUrls(data);
  const sanityPage = data?.sanityPage ?? {};
  const { _rawBody, seo, sidebar, invertHeroTheme, stretch } = sanityPage;
  const sidebarProps: SidebarProps = {
    ...sidebar,
    isDisplayed: sidebar?.isDisplayed,
    sidebarItemProps: sidebar?.links?.map((link) => {
      const href = getHRef(link);

      return {
        label: getLinkLabel(link) ?? undefined,
        href,
      };
    }),
  };

  const sideBarClassName = isLive(sanityPage) ? "" : "draft";
  const altHeroImage = getHero(
    data?.sanityPage?.hero2?.asset?.gatsbyImageData,
    data?.sanityPage?.hero?.asset?.gatsbyImageData,
    data?.sanityPage?.hero2?._rawAsset && {
      asset: data?.sanityPage?.hero2?._rawAsset,
      crop: data?.sanityPage?.hero2?._rawCrop,
      hotspot: data?.sanityPage?.hero2?._rawHotspot,
    },
    data?.sanityPage?.hero?._rawAsset && {
      asset: data?.sanityPage?.hero?._rawAsset,
      crop: data?.sanityPage?.hero?._rawCrop,
      hotspot: data?.sanityPage?.hero?._rawHotspot,
    },
    data?.hero
  );

  useHydrateAtoms([
    [invertedHeroAtom, invertHeroTheme],
    [heroAtom, altHeroImage],
    [seoAtom, seo],
  ]);
  return data?.sanityPage ? (
    <>
      <Helmet>
        <title> {sanityPage.seo.title || sanityPage.heading} | EP3 Guide</title>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <BreadCrumbs paths={[{ url: "/", label: "Home" }].concat(
              data?.sanityPage?.slug.current.split("/").slice(0, -1).map((path, index, array) => ({
                url: ("/" + array.slice(0, index + 1).join("/")).replace("//", "/"),
                label: titleCase(path.replace(/-/g, " ")),
              })).filter((item) => item.label) ?? []
            )} title={data?.sanityPage?.heading ?? ""} />
            <Title>{data?.sanityPage?.heading}</Title>
          </Header>
          <UnpublishedProvider value={badUrls}>
            <Main stretch={stretch} {...sidebarProps}>
              <div className={sideBarClassName + " " + data?.sanityPage.slug.current.replace("/", "")}>
                <DynamicComponent blocks={_rawBody!} />
              </div>
            </Main>
          </UnpublishedProvider>
          {data?.sanityPage?.includeEmailForm && (

            <div className="grid items-center justify-center w-full p-5 mt-4">
              <EmailListForm />
            </div>
          )}
        </Container>
      </Layout>
    </>
  ) : <>{`Something went wrong. (PageTemplate)`}</>;
};

export const pageQuery = graphql`
  query ($slug: String!) {
    hero: file(relativePath: { eq: "hero-random-page.jpg" }) {
      ...heroImageFragment
    }

    sanityPage(slug: { current: { eq: $slug } }) {
      heading
      includeEmailForm
      hero {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
        _rawAsset(resolveReferences: { maxDepth: 10 })
        _rawCrop(resolveReferences: { maxDepth: 10 })
        _rawHotspot(resolveReferences: { maxDepth: 10 })
      }

      hero2 {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
        _rawAsset(resolveReferences: { maxDepth: 10 })
        _rawCrop(resolveReferences: { maxDepth: 10 })
        _rawHotspot(resolveReferences: { maxDepth: 10 })
      }
      seo {
        title
        description
      }
      slug {
        current
      }
      invertHeroTheme
      publicationStatus
      stretch
      sidebar {
        isDisplayed
        header
        links {
          ... on SanityInternalLink {
            ...internalLinkFragment
          }
          ... on SanityLink {
            _key
            _type
            href
            label
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }

    allNonVersionedDocuments: allSanityEp3Document(filter: { publicationStatus: { ne: "live" } }) {
      nodes {
        slug {
          current
        }
        publicationStatus
      }
    }

    allNonVersionedPages: allSanityPage(filter: { publicationStatus: { ne: "live" } }) {
      nodes {
        slug {
          current
        }
        publicationStatus
      }
    }
  }
`;

export default PageTemplate;
