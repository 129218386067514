import { navigateTo } from "@/lib/navigateTo";
import * as React from "react";

export const smoothScrollOnClick = (evt: Event) => {
  evt.preventDefault();
  // @ts-ignore
  const [, href] = evt.target?.getAttribute("href").split("#");
  navigateTo(href ? document.querySelector(`#${href}`) : null);
};

export const useSmoothScroll = () => {
  React.useLayoutEffect(() => {
    // @ts-ignore
    document.querySelectorAll('a[href*="#"]').forEach((aTag) => {
      const [origin, href] = aTag.getAttribute("href")?.split("#") as string[];
      const localized = !(origin !== "" && origin !== window.location.pathname);
      if (!localized || !href) {
        return;
      }
      aTag.addEventListener("click", smoothScrollOnClick);
      return () => aTag.removeEventListener("click", smoothScrollOnClick);
    });
  }, []);
};
